<template>
  <div class="content">
    <div class="cover" v-if="ifChangeTable" @click="onCloseChangeTable"></div>
    <div class="change-date">
      <div class="date-btn" @click="onMinusDay"><left-outlined /></div>
      <div class="date-input" @click="showDatePicker">{{inputValue}}</div>
      <div class="date-btn" @click="onPlusDay"><right-outlined /></div>
    </div>
    <div class="dining-types">
      <div :class="{'type': true, 'on': diningType === 0}" @click="onChangeDiningType(0)">全部</div>
      <div :class="{'type': true, 'on': diningType === 1}" @click="onChangeDiningType(1)">午餐</div>
      <div :class="{'type': true, 'on': diningType === 2}" @click="onChangeDiningType(2)">晚餐</div>
    </div>
    <a-spin :spinning="loading">
      <ul
        :class="{
          list: true,
          'on-fixed': ifChangeTable,
        }"
      >
        <li
          v-for="(v, k) in list"
          :key="k"
          :class="{
            on: tableOrder && tableOrder.table.id === v.table.id,
          }"
        >
          <a :class="'state-' + v.table.state" @click.stop="onChoosetable(v)">
            <div class="li-title">{{ v.table.name }}</div>
            <div class="li-info">
              <div class="info-time" v-if="v.table.state === 1">
                <icon-font type="icon-31shijian" />
                {{ $filters.formatDate(v.order.bookTime, "HH:mm") }}
              </div>
              <div class="info-money" v-if="v.table.state > 0">
                ¥ {{ v.order.orderAmount }}
              </div>
            </div>
            <div class="li-info-2">
              <div class="info-seat">
                <icon-font type="icon-shafa" />
                {{ v.table.peopleCount }}
              </div>
              <div class="info-people" v-if="v.table.state > 0">
                <icon-font type="icon-yonghu2" />
                {{ v.order.peopleCount }}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </a-spin>
    <Footer />

    <van-popup v-model:show="show" position="center">
      <div class="btns">
        <div class="btn" @click="onChangeTable">换台</div>
        <a
          class="btn pre"
          @click.stop="
            $router.push({
              name: 'shareholder-book',
              params: { tableId: tableOrder.table.id },
              query: { bookDate: inputValue, diningType: diningType }
            })
          "
        >
          预订
        </a>
        <a
          class="btn det"
          @click.stop="
            $router.push({ name: 'shareholder-order', params: { id: tableOrder.order.id } })
          "
        >
          详情
        </a>
      </div>
    </van-popup>

    <van-popup v-model:show="show2" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :min-date="minDate"
        type="date"
        title="选择日期"
        @confirm="onChangeDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { ExclamationCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { TableClass } from '@/apis/table'
import { OrderClass } from '@/apis/order'
import Footer from './Footer'
import router from '@/router'
import { Modal } from 'ant-design-vue'
import { Popup, DatetimePicker } from 'vant'
import 'vant/es/popup/style'
import 'vant/es/datetime-picker/style'
import dayjs from 'dayjs'

const api = new TableClass()
const order = new OrderClass()
export default defineComponent({
  components: {
    Footer,
    LeftOutlined,
    RightOutlined,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker
  },
  setup () {
    const list = ref([])
    const loading = ref(true)
    const params = ref({})
    const show = ref(false)
    const tableOrder = ref()
    const ifChangeTable = ref(false)

    const currentDate = ref(new Date())
    const minDate = ref(new Date())
    const show2 = ref(false)
    const inputValue = ref('')

    const onGetTableList = () => {
      api.all(params.value).then((resp) => {
        list.value = resp
        loading.value = false
      })
    }

    const onChoosetable = (e) => {
      console.log(e)
      if (ifChangeTable.value) {
        onChangeTableFun(e.table)
        return
      }
      tableOrder.value = e
      if (e.order && e.order.id) {
        show.value = true
      } else {
        router.push({
          name: 'shareholder-book',
          params: { tableId: e.table.id },
          query: { bookDate: inputValue.value, diningType: diningType.value }
        })
      }
    }

    const onChangeTableFun = (table) => {
      Modal.confirm({
        title: '提示！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '是否将台位换到' + table.name
        ),
        onOk () {
          order.changeTable(tableOrder.value.order.id, table.id).then(() => {
            onCloseChangeTable()
            onGetTableList()
          })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const onCloseChangeTable = () => {
      ifChangeTable.value = false
    }

    const onChangeTable = () => {
      show.value = false
      ifChangeTable.value = true
    }

    const onChangeDate = (e) => {
      show2.value = false
      inputValue.value = dayjs(e).format('YYYY-MM-DD')

      params.value.BookDate = inputValue.value

      onGetTableList()
    }
    const showDatePicker = () => {
      show2.value = true
    }
    const onPlusDay = () => {
      const date = dayjs(currentDate.value).add(1, 'day')
      inputValue.value = date.format('YYYY-MM-DD')
      currentDate.value = new Date(date.format())

      params.value.BookDate = inputValue.value

      onGetTableList()
    }
    const onMinusDay = () => {
      const date = dayjs(currentDate.value).subtract(1, 'day')
      inputValue.value = date.format('YYYY-MM-DD')
      currentDate.value = new Date(date.format())

      params.value.BookDate = inputValue.value

      onGetTableList()
    }

    inputValue.value = dayjs().format('YYYY-MM-DD')

    const diningType = ref(0)
    const onChangeDiningType = (e) => {
      diningType.value = e
      params.value.DiningType = e

      onGetTableList()
    }

    return {
      list,
      loading,
      show,
      tableOrder,
      ifChangeTable,
      currentDate,
      show2,
      minDate,
      inputValue,
      params,
      diningType,

      onGetTableList,
      onChoosetable,
      onCloseChangeTable,
      onChangeTable,
      onChangeDate,
      showDatePicker,
      onPlusDay,
      onMinusDay,
      onChangeDiningType
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.timer = setInterval(() => {
        this.onGetTableList()
      }, 5000)
    })
  },

  unmounted () {
    clearInterval(this.timer)
  },

  created () {
    if (this.$route.query.bookDate) {
      this.inputValue = this.$route.query.bookDate
    }

    if (this.$route.query.diningType) {
      this.diningType = Number(this.$route.query.diningType)
    }

    this.params.BookDate = this.inputValue
    this.params.DiningType = this.diningType
    this.onGetTableList()
  },
  methods: {}
})
</script>

<style lang="less" scoped>
.content {
  background: #fff;
  min-height: 100vh;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem;
  padding-bottom: 1.2rem;
  li {
    width: 50%;
    padding: 0.1rem;

    a {
      display: block;
      background: #dff3dd;
      border-radius: 5px;
      border-top: 0.1rem solid #a8eba0;
      color: #333;
      overflow: hidden;
      position: relative;
      padding-bottom: 1.2rem;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      .li-title {
        font-size: 0.3rem;
        padding: 0.1rem;
        line-height: 0.5rem;
      }
      .li-info {
        bottom: 0.5rem;
        .info-time {
          padding-right: 0.1rem;
        }
      }
      .li-info-2 {
        bottom: 0;
        .info-people {
          padding-left: 0.2rem;
        }
      }
      .li-info,
      .li-info-2 {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0.1rem;
        width: 100%;
        font-size: 0.26rem;
        height: 0.5rem;
      }

      &.state-0 {
      }
      &.state-1 {
        border-color: #fcd63d;
        background: #f9edbf;
      }
      &.state-2 {
        border-color: #ff4f4f;
        background: #f9d6d6;
      }
    }

    &.on {
      a {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
    }
  }

  &.on-fixed {
    li {
      position: relative;
      z-index: 9999;
      &.on {
        z-index: 1;
      }
    }
  }
}

.btns {
  width: 4rem;
  padding: 0.4rem 0.2rem;
  .btn {
    display: block;
    width: 100%;
    height: 0.8rem;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.3rem;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background: #fff;
    &.pre,&.err,&.det {
      margin-top: 15px;
      border-color: #1890ff;
      color: #1890ff;
      box-shadow: 0 0 3px rgba(38, 0, 255, 0.2);
    }
    &.err{
      border-color: #ff1818;
      color: #ff1818;
    }
    &.det{
      border-color: #7c7c7c;
      color: #7c7c7c;
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
}

.change-date{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0.6rem;
  .date-btn{
    margin: 0 0.1rem;
  }
  .date-input{
    padding: 0 0.2rem;
  }
}
.dining-types{
  display: flex;
  justify-content: center;
  .type{
    width: 1rem;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #ccc;
    border-radius: 0.05rem;
    margin: 0 0.1rem;
    font-size: 0.24rem;
    color: #666;
    &.on{
      color: #fff;
      background: #1890ff;
      border-color: #1890ff;
    }
  }
}
</style>
